.gallery_header {
    height: 270px;
    width: 100%;
    background: url("../../../src/assests/image/sliderone.jpg"), linear-gradient(-180deg,rgba(0,0,0,0.2), rgba(0,0,0,0.5));
    background-blend-mode: darken;
    background-position: center;
    position: relative;
    .gallery_header_text {
        font-size: 36px;
        color: #fff;
        top: 70%;
        transform: translateY(-50%);
        left: 30px;
        position: absolute;
        &::after {
            content: "";
            height: 1px;
            width: 120px;
            background: #e1e1e1;
            right: -140px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }
}
.galleryWrapper {
    display: grid;
    margin-bottom: 120px;
    gap: 42px;
    grid-template-columns: repeat(4,1fr);
    margin-top: 100px;
    .galleryimageWrapper {
        height: 280px;
        width: 100%;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0 2px 12px #eee;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        transition: 1s;
        &:hover .image_text{
            visibility: visible;
            opacity: 1;
            bottom: 0;
        }
        .image_text {
            height: 80px;
            width: 100%;
            font-size: 20px;
            color: #fff;
            background: rgba(0,0,0,0.7);
            bottom: -80px;
            position: absolute;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            opacity: 0;
            transition: 1s;
        }
        img {
            height: 100%;
            width: 100%;
            border-radius: 6px;
            transition: 1s;
            &:hover {
                transform: scale(1.4);
            }
        }
    }
}
@media (max-width: 767px) {
    .galleryWrapper {
        display: grid;
        gap: 42px;
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 540px) {
    .galleryWrapper {
        display: grid;
        gap: 42px;
        grid-template-columns: repeat(1,1fr);
    }
}