body {
  background: url("../src/assests/image/textilebacklight.jpg");
  background-attachment: fixed;
  background-size: contain;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  background: skyblue;
  border-radius: 6px;
}