.mission_wrapper {
    margin: 80px 0 120px;
    .home_topmargin {
        margin-bottom: 120px;
    }
    .home_image {
        height: 460px;
        width: 100%;
        // border: 2px solid skyblue;
        background: linear-gradient(45deg, powderblue, lavender);
        border-radius: 4px;
        box-shadow: 0 0 12px lightgrey;
        img {
            height: 100%;
            width: 100%;
            border-radius: 6px;
            transform: scale(0.92);
            filter: grayscale(0.4);
        }
    }
    .home_right {
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h5 {
            font-size: 30px;
            position: relative;
            &::after {
                content: "";
                height: 4px;
                width: 160px;
                border-radius: 6px;
                background: "";
                left: 50%;
                bottom: -16px;
                transform: translate(-50%, -50%);
                position: absolute;
                background: linear-gradient(45deg, indigo, dodgerblue);
                animation: anim2 2s linear infinite alternate;
            }
        }
        @keyframes anim2 {
            0% {
                width: 0;
            }
            100% {
                width: 160px;
            }
        }
        p {
            margin-top: 24px;
        }
        p {
            margin-top: 24px;
        }
    }
}
