// @import url('https://fonts.googleapis.com/css2?family=WindSong&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.home_product_wrapper {
    .home_content_wrapper {
        margin: 100px 0 120px;
        .home_content {
            .home_topmargin {
                margin-bottom: 120px;
            }
            .home_right {
                height: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                h5 {
                    font-size: 30px;
                    position: relative;
                    &::after {
                        content: "";
                        height: 4px;
                        width: 160px;
                        border-radius: 6px;
                        background: "";
                        left: 50%;
                        bottom: -18px;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        background: linear-gradient(45deg, indigo, dodgerblue);
                        
                    }
                }
                @keyframes anim2 {
                    0% {
                        width: 0;
                    }
                    100% {
                        width: 160px;
                    }
                }
                p {
                    margin-top: 24px;
                }
            }
            .home_image {
                height: 440px;
                width: 100%;
                // border: 2px solid skyblue;
                background: linear-gradient(45deg, powderblue, lavender);
                border-radius: 4px;
                box-shadow: 0 0 12px lightgrey;
                img {
                    height: 100%;
                    width: 100%;
                    border-radius: 6px;
                    transform: scale(0.92);
                }
            }
            .custom_project_btn {
                height: 50px;
                width: fit-content;
                padding: 0 36px;
                border: none;
                color: #ffffff;
                background: linear-gradient(45deg, indigo, dodgerblue);
            }
            // .home_topmargin {
            //     margin-top: 120px;
            // }
        }
        .product-slider {
            margin: 20px 0 20px 0;
            height: 280px;
            padding: 32px 0;
            // border-top: 1px solid lightgrey;
            // border-bottom: 1px solid lightgrey;
            .swiper {
                height: 100%;
                .slider_wrapper {
                    height: 100%;
                    width: 100%;
                }
                .slider_image_wrapper {
                    height: 214px;
                    width: 100%;
                    border-radius: 6px;
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 6px;
                    }
                }
            }
        }
        .heading_text {
            font-size: 32px;
            color: #000;
            text-align: center;
            text-shadow: 1px 1px 2px #000;
            // font-family: 'WindSong', cursive;
            font-family: "Montserrat", sans-serif;
            padding: 12px 0;
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey;
            background: "#eee";
        }
    }
}
@media (max-width: 540px) {
    .home_product_wrapper {
        .home_content_wrapper {
            .home_content {
                .home_topmargin {
                    margin-bottom: 40px;
                }
                .home_image {
                    height: 280px;
                    width: 100%;
                }
                .home_right {
                    h5 {
                        margin-top: 20px;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
