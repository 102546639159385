.particleWrapper {
    position: relative;
    .contentWrapper {
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
            height: 100%;
            font-size: 420px;
            margin: 0;
            color: transparent;
            -webkit-text-stroke: 1px #333;
        }
    }
}