@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.contact_header {
    height: 270px;
    width: 100%;
    background: url("../../../src/assests/image/sliderone.jpg"), linear-gradient(-180deg,rgba(0,0,0,0.2), rgba(0,0,0,0.5));
    background-blend-mode: darken;
    background-position: center;
    position: relative;
    .contact_header_text {
        font-size: 36px;
        color: #fff;
        top: 70%;
        transform: translateY(-50%);
        left: 30px;
        position: absolute;
        &::after {
            content: "";
            height: 1px;
            width: 120px;
            background: #e1e1e1;
            right: -140px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }
}
.contact_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: 100px;
    h5 {
        font-size: 18px;
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        position: relative;
        &::after {
            content: "";
            height: 2px;
            width: 80px;
            bottom: -12px;
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            border-radius: 6px;
            background: linear-gradient(45deg, indigo, dodgerblue);
            animation: anim 2s linear infinite alternate;
        }
    }
    @keyframes anim {
        0% {
            width: 0;
        }
        100% {
            width: 80px;
        }
    }
    a{
        color: dodgerblue;
        text-decoration: none;
    }
}
.logo_image {
    height: 100px;
    width: 220px;
    img {
        height: 100%;
        width: 100%;
    }
}