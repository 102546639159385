@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.loader_wrapper {
    height: 100vh;
    width: 100%;
    background: url(../../assests/image/we.jpg),
        linear-gradient(45deg, rgba(255, 255, 255, 0.81), rgba(255, 255, 255, 0.81));
    background-repeat: no-repeat;
    // background-size: cover;
    background-blend-mode: lighten;
    background-position: 50% 100%;
    background-size: 220% 220%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: back 9s linear infinite;
    .wrapper {
        position: relative; 
    }
    .image_wrapper {
        height: 120px;
        width: 240px;
        margin-top: 0;
        animation: anime1 2s linear 1;
        img {
            height: 100%;
            width: 100%;
        }
    }
    .text_wrapper {
        margin-top: 20px;
        animation: texter 2s linear 1;
        p {
            color: #777;
            text-shadow:  1px 1px 4px lightgrey;
            font-size: 36px;
            letter-spacing: 8px;
            word-spacing: 2px;
            font-weight: 500;
            font-family: 'Montserrat', sans-serif;
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey;
            padding: 8px 0;
        }
    }
}
@keyframes back {
    0% {
        background-position: 50% 50%;
    }
    100% {
        background-position: 100% 100%;
    }
}
@keyframes anime1 {
    0% {
        margin-top:0px;
        opacity: 0;
    }
    1% {
        margin-top: 330px;
        opacity: 0;
    }
    100% {
        margin-top: 0;
        opacity: 1;
    }
}
@keyframes texter {
    0% {
        margin-top: 60px;
        opacity: 0;
    }
    100% {
        margin-top: 20px;
        opacity: 1;
    }
}

@media (max-width: 767px) {
    .loader_wrapper {
        .image_wrapper {
            height: 100px;
            width: 220px;
            img {
                height: 100%;
                width: 100%;
            }
        }
        .text_wrapper {
            p {
                font-size: 20px;
                letter-spacing: 8px;
                word-spacing: 2px;
                padding: 8px 0;
            }
        }
    }
}