.header-wrapper {
    height: 80px;
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 12px 12px;
    background: #eff7fd;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    z-index: 2;
    .navbar-brand {
        height: 48px;
        width: 120px;
        // animation: anim 3s linear infinite;
        img {
            height: 100%;
            width: 100%;
        }
    }
}
.activeclass {
        a {
            font-weight: 500;
            color: dodgerblue !important;
            transition: 2s;
        }
}
nav {
    button {
        &:focus {
            border: none;
            outline: 0;
            box-shadow: none !important;
            text-decoration: none;
        }
    }
}
.navbar-toggler {
    img {
        height: 30px;
        width: 30px;
    }
}
.navbar-nav {
    li {
        height: 48px;
        margin: 0 32px;
        a {
            height: 100%;
            color: #000;
            font-weight: 500;
            display: flex;
            align-items: center;
            text-decoration: none;
            position: relative;
            transition: 1s;
            &::after {
                content: "";
                height: 4px;
                width: 0;
                border-radius: 6px;
                background: dodgerblue;
                left: 0;
                bottom: -16px;
                position: absolute;
                visibility: hidden;
                opacity: 0;
                transition: 1s;
            }
        }
        a:hover::after {
            width: 100%;
            opacity: 1;
            visibility: visible;
            transition: 1s;
        }
    }
}
@media (max-width: 991px) {
    .header-wrapper {
        top: 12px;
        border-radius: 12px;
        height: auto;
    }
    .navbar-nav { 
        li {
            a{
                &::after {
                    display: none;
                }
            }
        }
    }
}