@import url('https://fonts.googleapis.com/css2?family=WindSong&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
.swiper {
    height: 100vh;
}
.slider_wrapper {
    .slider_head, .slider_head_white {
        top: 50%;
        left: 50%;
        font-size:80px;
        color: #000;
        text-shadow: 2px 2px 2px #000;
        transform: translate(-50%, -50%);
        position: absolute;
        font-family: 'WindSong', cursive;
        text-align: center;
    }
    .slider_head_white {
        color: #fff;
    }
    .slider_image_wrapper {
        height: 100vh;
        width: 100%;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
@media (max-width: 767px) {
    .swiper {
        height: 100vh;
    }
    .slider_wrapper {
        .slider_head, .slider_head_white {
            font-size: 36px;
            font-family: "Montserrat", sans-serif;
        }
    }
}