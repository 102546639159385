.about_header {
    height: 270px;
    width: 100%;
    background: url("../../../src/assests/image/sliderone.jpg"), linear-gradient(-180deg,rgba(0,0,0,0.2), rgba(0,0,0,0.5));
    background-blend-mode: darken;
    background-position: center;
    position: relative;
    .about_header_text {
        font-size: 36px;
        color: #fff;
        top: 70%;
        transform: translateY(-50%);
        left: 30px;
        position: absolute;
        &::after {
            content: "";
            height: 1px;
            width: 120px;
            background: #e1e1e1;
            right: -140px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }
    }
}
.about_slider {
    height: 300px !important;
    margin-bottom: 120px;
    .slider_image_wrapper {
        height: 300px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 120px;
        text-align: center;
        h5 {
            font-size: 18px;
            line-height: 26px;
        }
    }
}
.about_content_wrapper {
    margin-bottom: 60px;
}
// .swiper {
//     height: 350px !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-bottom: 130px;
//     h3 {
//         text-align: center;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
//     .slider_image_wrapper {
//         height: 350px !important;
//         padding: 140px;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
// }
.about_logo_wrapper {
    height: 140px;
    width: 220px;
    margin: 40px auto 40px;
    img {
        height: 100%;
        width: 100%;
    }
}
.certificates_wrapper {
    .logo_wrapper {
        height: 220px;
        width: 100%;
        border-radius: 6px;
        border: 1px dashed dodgerblue;
        box-shadow: 0 0 6px lightgrey;
        background: #fff;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            transform: scale(0.8);
        }
    }
}
@media (max-width: 767px) {
    .about_slider {
        .slider_image_wrapper {
            padding: 0 60px;
        }
    }
}